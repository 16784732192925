import React, { Component } from 'react';
import logo from './logo.png';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="content">
          <header className="App-header">
            <img className="logo" src={logo}/>
            <nav className="nav">
              <ol className="nav-list">
                <li>
                  <a href="#teams">Who we are</a>

                </li>
                <li>
                  <a href="#work">What we do</a>
                </li>
                <li>
                  <a href="#contact">Contact</a>
                </li>
              </ol>
            </nav>
          </header>
          <section id="callout">
            <h1>Smarter solutions, predictable results</h1>
            <h4>We develop customized software solutions that make your processes smarter and your bottom line stronger</h4>
          </section>
          <section id="value">
            <h2>Who we are</h2>
            <p>
             We work ahead of the curve. An accomplished team of national talent, including back-end, front-end and user interface developers and cloud platform engineers, we help move you ahead of the competition. Whether adding depth to a team or skills to a project, let Janes Consulting turn your objectives into results.
            </p>
          </section>
          <section id="industries">
            <h2>Where do we help?</h2>
            <p>We know the work. Janes Consulting hires qualified developers who share our passion for quality work. As a result, our team has a breadth of experience in a variety of industries, including: media, finance, publishing, agriculture, and marketing.</p>
          </section>
          <section id="work">
            <h2>What do we know?</h2>
            <p>We speak your language. We build enterprise level applications for Fortune 500 companies using the platforms that make the most sense for your unique business requirements.

              Our broad experience with diverse technologies and development practices enables us to seamlessly integrate into your current environment, reducing your time to market.</p>
            <h3>Core Languages</h3>
            <p>JavaScript (ES5 & ES2015), Node, TypeScript, Java, Scala, Groovy</p>
            <h3>Roles</h3>
            <p>Software Developers, QA Engineers, UX/UI Designers, Cloud Engineers, Architects, Tech Leads</p>
            <h3>Frameworks & Platforms</h3>
            <p>Amazon Web Services, D3.js, Spring, Spring Boot, React, Redux, React Native, RxJS, Oracle</p>
            <p id="dev-icons">
              <i className="devicon-amazonwebservices-plain-wordmark"></i>
              <i className="devicon-android-plain"></i>
              <i className="devicon-apple-original"></i>
              <i className="devicon-apache-plain-wordmark"></i>
              <i className="devicon-css3-plain-wordmark"></i>
              <i className="devicon-html5-plain-wordmark"></i>
              <i className="devicon-java-plain-wordmark"></i>
              <i className="devicon-javascript-plain"></i>
              <i className="devicon-nodejs-plain-wordmark"></i>
              <i className="devicon-oracle-original"></i>
              <i className="devicon-react-original-wordmark"></i>
              <i className="devicon-sass-original"></i>
              <i className="devicon-typescript-plain"></i>
            </p>
          </section>
          <section id="contact">
            <h2>Contact Us</h2>
            <p>
              Janes Consulting is headquartered in the Kansas City area.
            </p>
            <ul>
              <li>
                <i className="fas fa-envelope"></i> <a href="mailto:info@janes.io">info@janes.io</a>
              </li>
              <li>
                <i className="fas fa-phone"></i> <a href="tel:5517752637">(551) 775-2637</a>
              </li>
            </ul>

          </section>
        </div>
      </div>
    );
  }
}

export default App;
